<template>
  <div class="container" ref="wrapper">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
          <template #right>
            <div @click="toHome()">
              <van-icon name="wap-home-o" size="18" />
              <div style="float: right; margin-left: 5px; font-size: 15px">
                首页
              </div>
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>

      <div class="detail">
        <h2 style="text-align: center">
          {{ courses.title }}
        </h2>

        <van-tabs v-model="activeName2" sticky>
          <van-tab title="课程信息" name="a">
            <div>
              <div>
                <div class="acontent">
                  上课老师： {{ courses.teacherUserName }}
                </div>
                <div class="acontent">上课地点： {{ courses.address }}</div>
                <div class="acontent">上课时间： {{ courses.startTime }}</div>

                <div class="acontent">
                  课程时长： {{ courses.courseLength }} 小时
                </div>
                <div class="acontent">
                  状态： <span v-if="courses.status == 1">预约中</span>
                  <span v-if="courses.status == 2">已上课</span
                  ><span v-if="courses.status == 3">已取消</span>
                  <span v-if="courses.status == 4">筹备中</span>
                </div>
                <div
                  class="acontent"
                  v-if="courses.maxNumber != undefined && courses.maxNumber > 0"
                >
                  报名限额： {{ courses.maxNumber }}
                </div>
                <div
                  class="acontent"
                  v-if="courses.lastTime != undefined && courses.lastTime != ''"
                >
                  报名截止： {{ courses.lastTime }}
                </div>

                <div
                  class="acontent"
                  v-if="
                    courses.userGrade != undefined && courses.userGrade != ''
                  "
                >
                  职级限制： {{ courses.userGrade }}
                </div>
                <div
                  class="acontent"
                  v-if="
                    courses.departmentCode != undefined &&
                    courses.departmentCode != ''
                  "
                >
                  特定部门查看
                </div>

                <div
                  class="acontent"
                  v-if="courses.centreID != undefined && courses.centreID > 0"
                >
                  特定中心查看
                </div>

                <div
                  class="acontent"
                  v-if="courses.isUserLimit != undefined && courses.isUserLimit"
                >
                  指定人员查看
                </div>
                <div
                  class="acontent"
                  v-if="courses.mustPartner != undefined && courses.mustPartner"
                >
                  仅合伙人查看
                </div>
                <div class="acontent" v-if="courses.limitScore != undefined">
                  或学习积分大于 {{ courses.limitScore }} 您的学习积分
                  {{ courses.userCourseScore }}
                </div>

                <div class="acontent">课程简介： {{ courses.summary }}</div>
                <div class="acontent">图文详情:</div>
                <div class="acontent" v-html="courses.detailsHtml"></div>
              </div>
              <div
                v-if="!hassign && (courses.status == 1 || courses.status == 4)"
                class="btn-box"
              >
                <div
                  v-if="courses.inLastTime == undefined || courses.inLastTime"
                >
                  <van-button
                    type="info"
                    size="large"
                    round
                    block
                    @click="onSign"
                    v-if="
                      courses.maxNumber == undefined ||
                      courses.maxNumber == 0 ||
                      courses.maxNumber > courses.subscribeCount
                    "
                    :disabled="!courses.canRead"
                    ><span> 预约</span>

                    <span
                      v-if="
                        courses.maxNumber > 0 &&
                        courses.maxNumber < courses.subscribeCount + 5
                      "
                    >
                      仅剩余{{
                        courses.maxNumber - courses.subscribeCount
                      }}个名额</span
                    >
                  </van-button>
                  <van-button
                    type="info"
                    size="large"
                    round
                    block
                    disabled="false"
                    v-else
                  >
                    报名名额已满</van-button
                  >
                </div>
                <div v-else>
                  <van-button
                    type="info"
                    size="large"
                    round
                    block
                    disabled="false"
                  >
                    报名已结束</van-button
                  >
                </div>
              </div>
              <div v-if="hassign" class="btn-box">
                <van-button
                  type="warning"
                  size="large"
                  round
                  block
                  @click="onSignCancle"
                >
                  您已预约，取消预约
                </van-button>
              </div>
            </div>
          </van-tab>
          <van-tab title="回看视频" name="b">
            <div v-if="courses.canRead">
              <VideoAudioPlayer
                :courses="courses"
                :browsingLogType="11"
              ></VideoAudioPlayer>
            </div>
            <div class="acontent" v-html="courses.detailsHtml3"></div>
            <van-empty
              v-if="
              (courses.videoList== undefined ||
                  courses.videoList.length == 0) &&
                (courses.videoUrl == undefined ||
                  courses.videoUrl.length < 10) &&
                (courses.audioUrl == undefined ||
                  courses.audioUrl.length < 10) &&
                (courses.detailsHtml3 == undefined ||
                  courses.detailsHtml3.length < 10)
              "
              description="暂无内容"
              image="/image/empty-image-default.png"
            />

            <van-empty
              v-if="courses.canRead == false"
              description="您的暂无权限回看，继续加油！"
              image="/image/empty-image-default.png"
            />
            
          </van-tab>
          <van-tab title="回看课件" name="c">
            <div v-if="courses.canRead">
              <div class="acontent" v-html="courses.detailsHtml2"></div>
            </div>

            <van-empty
              v-if="
                courses.detailsHtml2 == undefined ||
                courses.detailsHtml2.length < 10
              "
              description="暂无内容"
              image="/image/empty-image-default.png"
            />

            <van-empty
              v-if="courses.canRead == false"
              description="您的暂无权限回看，继续加油！"
              image="/image/empty-image-default.png"
            />
          </van-tab>
        </van-tabs>

        <div v-if="courses.isCoalition && courses.coalitionItemList.length>0">
              <h2>组合课程</h2>
              <van-cell-group>
                <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->

                <van-cell
                  @click="toDetailWithScroll(item)"
                  v-for="item in courses.coalitionItemList"
                  :key="item.id.toString()"
                >
                  <van-col span="8"
                    ><van-image
                      lazy-load
                      class="w100"
                      fit="contain"
                      style="width: 110px; height: 120px"
                      :src="item.imageUrl"
                    />
                  </van-col>
                  <van-col span="16" align="bottom">
                    <div style="margin-left: 20px">
                      <div
                        style="display: table-cell; vertical-align: middle"
                        class="zntitle van-multi-ellipsis--l3"
                      >
                        <h3 style="display: inline-block">{{ item.title }}</h3>
                      </div>

                      <div style="width: 100%; color: #696969">
                        讲师： {{ item.teacherUserName }}
                      </div>
                      <div style="width: 100%; color: #696969">
                        开始时间： {{ item.startTime }}
                      </div>

                      <div style="float: right; margin-right: 13px">
                        浏览{{ item.readCount }}人次
                      </div>

                      <div style="width: 100%">
                        <van-tag
                          v-if="item.status == 1"
                          size="large"
                          color="rgb(255 242 219)"
                          text-color="#9d1d22"
                          >预约中
                        </van-tag>
                        <van-tag
                          v-if="item.status == 2"
                          size="large"
                          color="rgb(255 242 219)"
                          text-color="#9d1d22"
                          >已开课
                        </van-tag>
                        <van-tag
                          v-if="item.status == 3"
                          size="large"
                          color="rgb(255 242 219)"
                          text-color="#9d1d22"
                          >已取消
                        </van-tag>

                        <van-tag
                          v-if="item.status == 4"
                          size="large"
                          color="rgb(255 242 219)"
                          text-color="#9d1d22"
                          >筹备中
                        </van-tag>
                        &nbsp;&nbsp;
                        <span
                          v-if="
                            item.hasSignUp != undefined &&
                            item.hasSignUp &&
                            (item.status == 1 || item.status == 4)
                          "
                          style="color: #c7861b; font-weight: bold"
                          >已报名
                        </span>

                        <!-- &nbsp;&nbsp;
               <span
                  v-if="item.hasSignIn!=undefined && item.hasSignIn"
                 style=" color: #c7861b; font-weight: bold"
                  >已签到
                </span> -->
                        &nbsp;&nbsp;
                        <van-tag
                          v-if="
                            item.audioUrl != undefined &&
                            item.audioUrl.length > 5
                          "
                          mark
                          plain
                          color="#9d1d22"
                          >有音频
                        </van-tag>
                        &nbsp;&nbsp;
                        <van-tag
                          v-if="
                            item.videoUrl != undefined &&
                            item.videoUrl.length > 5
                          "
                          mark
                          plain
                          color="#9d1d22"
                          >有视频
                        </van-tag>
                        <div
                          v-if="item.browsingTime != undefined"
                          style="float: left; color: #c7861b; font-weight: bold"
                        >
                          {{
                            item.browsingTime != undefined
                              ? getbrowsingTimeStr(item)
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </van-col>
                </van-cell>
              </van-cell-group>
            </div>

        <div
          v-if="
            coursesscore != undefined &&
            coursesscore.score != undefined &&
            coursesscore.score > 0
          "
        >
          <h2 style="padding-left: 12px">
            线下评分:
            <span>{{
              (coursesscore.score / coursesscore.sCount).toFixed(1)
            }}</span>
            共有{{ coursesscore.sCount }}人评价
          </h2>
          <van-divider />
        </div>
        <commentScore
          :itemId="courses.id"
          itemType="5"
          v-if="courses.status == 2"
        ></commentScore>

        <van-tabs v-model="activeName" sticky>
          <van-tab title="评价" name="a">
            <div class="tabcontent">
              <comment :CommentPage="CommentPage" :showlike="false"></comment>
            </div>
          </van-tab>

          <van-tab title="已预约" name="b">
            <div class="tabcontent">
              <Browsinglog :filterData="BrowsinglogfilterData"></Browsinglog>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getCourseOffline,
  getCourseOfflineGetScore,
} from "@/api/courseoffline";
import CommentScore from "../components/commentScore";
import Comment from "../components/comment";
import Browsinglog from "../components/Browsinglog";
import VideoAudioPlayer from "../components/videoAudioPlayer";

import {
  CancelBrowsingLog,
  AddBrowsingLog,
  QueryBrowsingLog,
} from "@/api/browsingLog";

export default {
  name: "zncourseofflinedetail",
  data() {
    return {
      loading: true,
      title: "",
      activeName: "a",
      activeName2: "a",
      hassign: false,
      courses: {},
      coursesscore: {},
      BrowsinglogfilterData: {
        itemId: "",
        itemType: 10, //预约
      },
      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 13,
        },
      },
    };
  },
  components: {
    CommentScore,
    Comment,
    Browsinglog,
    VideoAudioPlayer,
  },
  updated() {
    this.addpreview();
  },
  activated() {
    try {
      this.onLoad();
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    $route(to, from) {
      console.log("sunyifeng", to.query.para);
      //监听路由是否变化
      if (to.path == "/course/courseofflinedetail") {
        if (to.query.para !== from.query.para) {
          this.loading = true;
          this.initData();
          this.onLoad();
        }
      }
    },
  },
  mounted() {},

  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
    async onLoad() {
      let para = this.$route.query.para;

      let bresult = await QueryBrowsingLog({
        id: 0,
        itemId: para,
        itemType: 10,
        currentTime: 0,
        totalTime: 0,
      });

      if (bresult.data.success) {
        this.hassign = true;
      } else {
        this.hassign = false;
      }

      let aresult = await getCourseOffline(para); //({ ...this.page })
      this.courses = aresult.data.data;
      document.title = this.courses.title;

      let sresult = await getCourseOfflineGetScore(para);
      this.coursesscore = sresult.data.data;

      //可以回看
      if (
        this.courses.status == 2 &&
        this.courses.canRead &&
        ((this.courses.videoUrl != undefined &&
          this.courses.videoUrl.length > 10) ||
          (this.courses.audioUrl != undefined &&
            this.courses.audioUrl.length > 10) ||
          (this.courses.detailsHtml2 != undefined &&
            this.courses.detailsHtml2.length > 20))
      ) {
        this.activeName2 = "b";
      }

      this.title = "线下课程预约";
      this.CommentPage.filterData.itemId = this.courses.id;
      this.BrowsinglogfilterData.itemId = this.courses.id;
      this.loading = false;
    },
    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.courses.id);
        });
      });
      this.$previewRefresh();
    },
    async onSign() {
      await this.$dialog.confirm({
        title: "报名确认",
        message: "如无必要事情不接受取消；请大家按照课程时间准时参课",
      });
      this.hassign = true;
      let result = await AddBrowsingLog({
        itemId: this.courses.id,
        itemType: 10,
      });
      if (result.data.success) {
        Toast("预约成功");
        this.hassign = true;
      } else {
        this.hassign = false;
        Toast("预约失败," + result.data.errorMessage);
      }
    },
    async onSignCancle() {
      this.hassign = false;
      let result = await CancelBrowsingLog({
        itemId: this.courses.id,
        itemType: 10,
      });
      if (result.data.success) {
        Toast("取消预约成功");
        this.hassign = false;
      } else {
        this.hassign = true;
        Toast("取消预约失败," + result.data.errorMessage);
      }
    },
    toDetailWithScroll(item) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.toDetail(item);
    },
    toDetail(item) {
      this.$router.push({
        path: "/course/courseofflinedetail",
        query: { para: item.id },
      });
    },
    getbrowsingTimeStr(item) {
      if (item.browsingTime.part && item.browsingTime.part != "") {
        return "已学" + item.browsingTime.part + "章节";
      }
      if (item.browsingTime.percent >= 0.01) {
        if (item.browsingTime.percent == 1) {
          return "已学完";
        } else {
          return "已学" + (item.browsingTime.percent * 100).toFixed(0) + "%";
        }
      } else {
        return ""; //"未学习"
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  padding: 5px 5px 5px 5px;
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
  /deep/ p {
    padding: revert;
    margin: revert;
  }
}

.tabcontent {
  height: 100%;
}
.container {
  height: 100%;
  // height: inherit;
  // //overflow: hidden;
  overflow-y: auto;
  // box-sizing: border-box;
  //position: absolute;
  /deep/ .van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tabs__content {
  height: 100%;
}

.van-tab__pane {
  height: 100%;
}
</style>
